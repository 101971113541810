@use '@angular/material';

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    src: url('#{$file-path}.eot');
    src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
      url('#{$file-path}.woff') format('woff'),
      url('#{$file-path}.ttf') format('truetype');
  }
}

$fallback-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$heading-font: 'Sequel 100 Black';
$body-font: Montserrat;

$config: material.define-typography-config(
  $font-family: (
    $body-font,
    $fallback-font
  ),
  $headline-1:
    material.define-typography-level(
      2.75rem,
      1.25,
      700,
      ($heading-font, $fallback-font)
    ),
  $headline-5:
    material.define-typography-level(
      2.5rem,
      1.25,
      700,
      ($heading-font, $fallback-font)
    ),
  $headline-6:
    material.define-typography-level(
      2rem,
      1.25,
      700,
      ($heading-font, $fallback-font)
    ),
  $subtitle-1:
    material.define-typography-level(
      1.5rem,
      1.25,
      600,
      ($heading-font, $fallback-font)
    ),
  $subtitle-2:
    material.define-typography-level(
      1.25rem,
      1.25,
      600,
      ($heading-font, $fallback-font)
    ),
  $body-1: material.define-typography-level(1rem, 1.4, 500),
  $body-2: material.define-typography-level(0.875rem, 1.4),
  $caption: material.define-typography-level(0.75rem, 1.25),
  $button: material.define-typography-level(0.875rem, 1.4, 600),
  /*$input: material.define-typography-level(0.875rem, 1.4, 500) TODO NiJo reactivate*/
);

@mixin display-1 {
  @include material.typography-level($config, headline-1);
}

@mixin heading {
  @include material.typography-level($config, headline-5);
}

@mixin title {
  @include material.typography-level($config, headline-6);
}

@mixin subtitle-2 {
  @include material.typography-level($config, subtitle-1);
}

@mixin subtitle-1 {
  @include material.typography-level($config, subtitle-2);
}

@mixin lead {
  @include material.typography-level($config, body-1);
}

@mixin body {
  @include material.typography-level($config, body-2);
}

@mixin caption {
  @include material.typography-level($config, caption);
}

@mixin button {
  @include material.typography-level($config, button);
}

/*@mixin input {
  @include material.typography-level($config, input);
} TODO NiJo reactivate */

@include font-face(
  'Sequel 100 Black',
  '/assets/fonts/Sequel100Black-65/Sequel100Black-65',
  600
);
@include font-face(
  'Sequel 100 Black',
  '/assets/fonts/Sequel100Black-75/Sequel100Black-75',
  700
);

.mat-typography {
  .display-1 {
    @include display-1;
  }

  .lead {
    @include lead;
  }

  small {
    @include caption;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
