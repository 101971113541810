@use 'colors';
@use 'typography';
@use 'variables';
@use 'shadow';

/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
mat-slider.mat-mdc-slider {
  display: block;
  padding: 0;
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  &.mat-slider-horizontal {
    height: 2rem;
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-wrapper {
      top: 1rem;
      left: 0;
      right: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-track-wrapper {
      height: 0.25rem;
      border-radius: 10px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-track-background,
    .mat-slider-track-fill {
      height: 100%;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label {
      width: auto;
      height: auto;
      border-radius: map-get(variables.$border-radius, xs);
      background-color: #000;
      padding: 0.375rem 0.875rem;
      @include shadow.elevate();

      &-text {
        font-weight: 400;
        font-size: 0.625rem;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label {
      right: 0;
      top: -2rem;
      transform: translateX(50%) scale(0.01);
      transform-origin: bottom;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label-text {
      transform: none;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  &.mat-slider-min-value {
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    &.mat-slider-thumb-label-showing.cdk-focused {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb {
        border-color: map-get(colors.$theme-bg, light);
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb-label {
        background-color: #000;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    &:not(.mat-slider-thumb-label-showing),
    &:not(.mat-slider-thumb-label-showing):hover {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb {
        border-color: transparent;
      }
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  &:not(.mat-slider-disabled) {
    &.cdk-focused {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb {
        background-color: map-get(colors.$theme-bg, light);
        border-width: 0.125rem;
        transform: scale(1) !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-thumb-label {
        border-radius: 0;
        transform: translateX(50%) scale(1);
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label {
      border-radius: 0;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
    .mat-slider-thumb-label-text {
      opacity: 1;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
  .mat-slider-thumb {
    height: 0.75rem;
    width: 0.75rem;
    border-color: transparent;
    bottom: -0.4375rem;
    right: -0.4375rem;
    transform: scale(1);
    transition: background-color variables.$transition,
      border-color variables.$transition;

    &-label {
      transition: transform variables.$transition;
    }
  }

  @mixin color($name, $color) {
    &[color='#{$name}'] {
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      &:not(.mat-slider-disabled) {
        &.cdk-focused {
          /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
          .mat-slider-thumb {
            background-color: map-get(colors.$theme-bg, light);
            border-color: $color;
          }
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      &.mat-slider-min-value {
        /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
        .mat-slider-thumb {
          background-color: $color;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
      .mat-slider-track-wrapper .mat-slider-track-background {
        background-color: lighten(desaturate($color, 78%), 25%);
      }
    }
  }

  @include color(primary, colors.$primary);
  @include color(accent, colors.$accent);
  @include color(warn, colors.$warn);
}
