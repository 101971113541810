@use 'shadow';
@use 'colors';

.mat-drawer-container .mat-drawer-content {
  overflow: hidden;
}

.mat-drawer:not(.mat-drawer-side) {
  @include shadow.elevate(lg, colors.$muted);
}
