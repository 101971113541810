@use 'variables';
@use 'colors';
@use 'shadow';

/* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
.mat-menu {
  &-item[mat-menu-item] {
    font-weight: 500;
    height: 2.5rem;
    line-height: 2.5rem;
    transition: background-color variables.$transition;

    .vlt-icon,
    .icon {
      width: 1rem;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  }

  &-panel[role='menu'] {
    min-width: 13.75rem;
    @include shadow.elevate(sm, darken(colors.$primary-dark, 60%), 0.8);
  }
}
