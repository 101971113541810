@use 'variables';

.owl-theme .owl-dots {
  position: absolute;
  z-index: 1;
  bottom: 1.5rem;
  left: 50%;
  transform: translateX(-50%);

  .owl-dot {
    span {
      width: 0.5rem;
      height: 0.5rem;
      margin: 0.25rem;
      background: #fff;
      transition: width variables.$transition;
    }

    &:hover span {
      width: 1rem;
      background: #fff;
    }

    &.active span {
      width: 1.75rem;
      background: #fff;
    }
  }
}
