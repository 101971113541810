@use '@angular/material';
@use './light-theme';
@use './typography';
@use './utils';
@use './colors';

@import './components';

@include material.core();
@include material.all-component-typographies(typography.$config);
@include material.all-component-themes(light-theme.$config);

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

a {
  color: colors.$primary;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;

  &:hover {
    color: colors.$primary-lighter;
  }
}

form {
  width: 100%;
}

input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}

button {
  @include typography.button;
  cursor: pointer;
}

img {
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

small strong,
strong small {
  font-weight: 500;
}

[hidden] {
  display: none !important;
}

/* Fix the Diaglog in v15 */ 
.mat-mdc-dialog-container {
  .mdc-dialog__surface {
      padding: 24px;
  }
  --mdc-dialog-supporting-text-size: 0.875rem;
}
.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-dialog-container, .mdc-dialog .mdc-dialog__content {
  color: text-gray-800 !important;
}

.mdc-tooltip .mdc-tooltip__surface {
  background-color: #000;
}