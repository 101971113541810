@use '../variables';
@use '../shadow';

/* TODO(mdc-migration): The following rule targets internal classes of tooltip that may no longer apply for the MDC version. */
mat-tooltip-component .mat-mdc-tooltip {
  border-radius: map-get(variables.$border-radius, xs);
  background-color: #000;
  padding: 0.375rem 0.875rem;
  margin: 0.125rem;
  @include shadow.elevate();
}
