@use 'colors';

.ngx-ic-cropper {
  &::after {
    opacity: 1 !important;
    border: none !important;
  }

  .ngx-ic-resize {
    $offset-a: -0.85rem !important;
    $offset-b: calc(50% - 0.85rem) !important;
    z-index: 2 !important;
    pointer-events: none;

    &.ngx-ic-top {
      top: $offset-a;
      left: $offset-b;
    }

    &.ngx-ic-right {
      top: $offset-b;
      right: $offset-a;
    }

    &.ngx-ic-bottom {
      bottom: $offset-a;
      left: $offset-b;
    }

    &.ngx-ic-left {
      top: $offset-b;
      left: $offset-a;
    }

    &.ngx-ic-topleft,
    &.ngx-ic-topright,
    &.ngx-ic-bottomright,
    &.ngx-ic-bottomleft {
      opacity: 0 !important;
    }
  }

  .ngx-ic-square {
    background: map-get(colors.$theme-bg, light) !important;
    width: 0.5rem !important;
    height: 0.5rem !important;
    border: 0.125rem solid colors.$primary !important;
    border-radius: 50% !important;
  }
}
