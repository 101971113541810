@use 'colors';

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.muted {
  color: colors.$muted;
}

.asterisk {
  color: colors.$warn;
}

@mixin absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
