@use 'palettes';

$primary: map-get(palettes.$primary, 500);
$primary-filter: invert(38%) sepia(28%) saturate(2838%) hue-rotate(208deg)
  brightness(105%) contrast(101%);
$on-primary: map-get(map-get(palettes.$primary, contrast), 500);
$primary-lighter: map-get(palettes.$primary, 100);
$primary-light: map-get(palettes.$primary, 50);
$primary-dark: map-get(palettes.$primary, 700);

$accent: map-get(palettes.$accent, 500);
$on-accent: map-get(map-get(palettes.$accent, contrast), 500);

$warn: map-get(palettes.$warn, 500);
$on-warn: map-get(map-get(palettes.$warn, contrast), 500);
$warn-light: map-get(palettes.$warn, 50);

$muted: map-get(palettes.$grey, 500);
$muted-lighter: map-get(palettes.$grey, 300);
$muted-light: map-get(palettes.$grey, 200);

$midnight: map-get(palettes.$grey, 900);
$on-midnight: map-get(map-get(palettes.$grey, contrast), 900);

$teal: map-get(palettes.$teal, 500);

$theme-bg: (
  light: #fff,
  dark: #000
);

$theme-fg: (
  light: #000,
  dark: #fff
);
